export const API_ROOT = 'Invoice';
export const API_SEARCH_URL = `${process.env.REACT_APP_API_URL}${API_ROOT}/Search`;
export const API_GET_URL = `${process.env.REACT_APP_API_URL}${API_ROOT}`;
export const API_GENERATE_PDF_URL = `${process.env.REACT_APP_API_URL}${API_ROOT}/GeneratePdf`;
export const API_GET_COMPANY_LOGO_URL = `${process.env.REACT_APP_API_URL}Company/GetCompanyLogo/`;
export const API_FORM_URL = `${process.env.REACT_APP_API_URL}${API_ROOT}`;
export const PAGE_SIZE = 6;
export const TABLE_NAME = "Invoices";
export const INIT_VALUES = {
    id: '',
    number: '2025001',
    date: new Date(),
    header: '',
    customerId: '',
}
export const MODAL_CREATE_FORM_TARGET = "modal_invoice_create";
export const MODAL_EDIT_FORM_TARGET = "modal_invoice_edit_";
export const INTL_TRANSLATIONS_ID = {
    list: 'TEXT.INVOICES',
    create: 'TEXT.INVOICE.CREATE',
    edit: 'TEXT.INVOICE.EDIT'
}