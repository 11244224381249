/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget5,
} from '../../../_metronic/partials/widgets'
import WidgetAnnualCustomers, { AnnualCustomersRefreshHandle } from './WidgetAnnualCustomers'
import WidgetAnnualReceipt, { AnnualReceiptRefreshHandle } from './WidgetAnnualReceipt'
import WidgetCountryReceipt, { CountryReceiptRefreshHandle } from './WidgetCountryReceipt'
import WidgetCategoryReceipt, { CategoryReceiptRefreshHandle } from './WidgetCategoryReceipt'
import WidgetCustomerReceipt, { CustomerReceiptRefreshHandle } from './WidgetCustomerReceipt'
import WidgetCounts, { CountsRefreshHandle } from './WidgetCounts'
import WidgetMonthlyReceipt, { MonthlyReceiptRefreshHandle } from './WidgetMonthlyReceipt'
import { InvoiceForm as CreateForm } from '../invoice/InvoiceForm'
import axios from 'axios'

const DashboardPage: React.FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget5 />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const countsRefresh = React.useRef<CountsRefreshHandle>(null);
  const monthlyReceiptRefresh = React.useRef<MonthlyReceiptRefreshHandle>(null);
  const annualReceiptRefresh = React.useRef<AnnualReceiptRefreshHandle>(null);
  const countryReceiptRefresh = React.useRef<CountryReceiptRefreshHandle>(null);
  const categoryReceiptRefresh = React.useRef<CategoryReceiptRefreshHandle>(null);
  const customerReceiptRefresh = React.useRef<CustomerReceiptRefreshHandle>(null);
  const annualCustomersRefresh = React.useRef<AnnualCustomersRefreshHandle>(null);
  const [currentYear, setCurrentYear] = useState(0);
  const [invoiceYears, setInvoiceYears] = useState<number[]>([]);

  const INIT_VALUES = {
    id: '',
    number: '2025001',
    date: new Date(),
    header: '',
    customerId: '',
  }

  const GET_INVOICE_YEARS_URL = `${process.env.REACT_APP_API_URL}Invoice/GetYears`;
  useEffect(() => {
    setTimeout(() => {
      axios.get(GET_INVOICE_YEARS_URL)
        .then((response: any) => {
          setInvoiceYears(response.data);
          let currentYear = localStorage.getItem('CurrentYear');
          setCurrentYear(currentYear ? +currentYear : 0);
        })
    }, 1000)
  }, []);

  const handleChangeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem("CurrentYear", e.target.value);
    window.location.reload();
  };

  const loadData = () => {

    if (countsRefresh.current) {
      // start() has type inferrence here as well
      countsRefresh.current.refresh();
    }

    if (monthlyReceiptRefresh.current) {
      // start() has type inferrence here as well
      monthlyReceiptRefresh.current.refresh();
    }

    if (annualReceiptRefresh.current) {
      // start() has type inferrence here as well
      annualReceiptRefresh.current.refresh();
    }

    if (annualCustomersRefresh.current) {
      // start() has type inferrence here as well
      annualCustomersRefresh.current.refresh();
    }

    if (countryReceiptRefresh.current) {
      // start() has type inferrence here as well
      countryReceiptRefresh.current.refresh();
    }

    if (categoryReceiptRefresh.current) {
      // start() has type inferrence here as well
      categoryReceiptRefresh.current.refresh();
    }

    if (customerReceiptRefresh.current) {
      // start() has type inferrence here as well
      customerReceiptRefresh.current.refresh();
    }

  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <CreateForm searchMethod={loadData} initialValues={INIT_VALUES} />
      <PageTitle title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}>
        <button
          className='btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary fw-bolder me-5'
          onClick={() => {
            window.history.back();
          }}
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr021.svg'
            className='svg-icon-5 svg-icon-gray-500 me-1'
          />
          {intl.formatMessage({ id: 'TEXT.BACK' })}
        </button>
        <button
          className="btn-icon pulse pulse-white btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary fw-bolder me-5"
          onClick={() => {
            window.location.reload();
          }}
        >
          <KTSVG
            path='/media/icons/duotune/coding/cod006.svg'
            className='svg-icon-5'
          />
          <span className="pulse-ring"></span>
        </button>
        <div className='fv-row me-5'>
          <div className='position-relative'>
            <select
              name="currentYear"
              className="form-select form-select-solid"
              value={currentYear}
              onChange={e => {
                handleChangeYear(e);
              }}
            >
              {invoiceYears.map(item => (
                <option
                  key={item}
                  label={item.toString()}
                  value={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          className='btn btn-success font-weight-bold ml-10 ps-5 pe-10'
          data-bs-toggle='modal'
          data-bs-target='#modal_invoice_create'>
          <KTSVG
            path='/media/icons/duotune/finance/fin008.svg'
            className='svg-icon-5'
          />
          <span>
            {intl.formatMessage({ id: 'TEXT.INVOICE.CREATE' })}
          </span>
        </button>
      </PageTitle>

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <WidgetCounts ref={countsRefresh} />
        </div>
        <div className="col-xl-8">
          {/* begin::Chart widget 11 */}
          <WidgetMonthlyReceipt ref={monthlyReceiptRefresh} />
          {/* end::Chart widget 11 */}
        </div>
      </div>
      {/* end::Row */}
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <WidgetAnnualReceipt ref={annualReceiptRefresh} />
        </div>
      </div>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-6'>
          <WidgetCountryReceipt ref={countryReceiptRefresh} />
        </div>
        <div className='col-xxl-6'>
          <WidgetCategoryReceipt ref={categoryReceiptRefresh} />
        </div>
      </div>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-6'>
          <WidgetCustomerReceipt ref={customerReceiptRefresh} />
        </div>
        <div className='col-xxl-6'>
          <WidgetAnnualCustomers ref={annualCustomersRefresh} />
        </div>
      </div>
      {/* end::Row */}
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
