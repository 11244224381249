/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

export function SettingsMenu() {
  const intl = useIntl()
  return (
    <div className='row' data-kt-menu-dismiss='true'>
      <div className='col-lg-4 border-left-lg-1'>
        <div className='menu-inline menu-column menu-active-bg'>
          <div className='menu-item'>
            <Link to='/vats' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'TEXT.VATS' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/currencies' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'TEXT.CURRENCIES' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/countries' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'TEXT.COUNTRIES' })}</span>
            </Link>
          </div>
          <div className='menu-item'>
            <Link to='/settings' className='menu-link'>
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
              <span className='menu-title'>{intl.formatMessage({ id: 'TEXT.SETTINGS' })}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}